import * as bootstrap from 'bootstrap'
import './Products/main.js'
import './Gallery/main.js'
import './Gallery/main.js'
import ahoy from 'ahoy.js'
import "@klaxit/cookie-consent/dist/cookie-consent.js"
import { tns } from "tiny-slider/src/tiny-slider"

import * as Turbo from '@hotwired/turbo'
Turbo.start()
document.addEventListener("turbo:load", onLoad);
function onLoad() {

  let stronoToggel = document.getElementById('storno-toggel')
  if (stronoToggel){
   stronoToggel.addEventListener('click', (event) => {
      if (stronoToggel.dataset.open == "true"){
        stronoToggel.innerHTML = "Artikel stonieren"
        stronoToggel.dataset.open = false
        document.querySelectorAll('.strono-mode').forEach(e => e.style.display = "none")
        document.querySelectorAll('.product-name').forEach(e => {
          e.classList.add("col-md-4")
          e.classList.remove("col-md-3")
        })
      } else {
        stronoToggel.innerHTML = "Artikel nicht stonieren"
        stronoToggel.dataset.open = true
        document.querySelectorAll('.strono-mode').forEach(e => e.style.display = "block")
        document.querySelectorAll('.product-name').forEach(e => {
          e.classList.remove("col-md-4")
          e.classList.add("col-md-3")
        })

      }
    })
  }

  document.querySelectorAll('.modal.show').forEach(x => {
    x.classList.remove("show");
    x.style.display = "none";
    if (document.querySelector('.modal-backdrop')) document.querySelector('.modal-backdrop').remove()
    document.querySelector('body').classList.remove("modal-open");
    document.querySelector('body').style.overflow = "auto";
  })

  var left = '<svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 24 24"><path fill="currentColor" d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M15.4,16.6L10.8,12L15.4,7.4L14,6L8,12L14,18L15.4,16.6Z" /></svg>'
  var right = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M6,13V11H14L10.5,7.5L11.92,6.08L17.84,12L11.92,17.92L10.5,16.5L14,13H6M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12Z" /></svg>'

  document.querySelectorAll('.slider-container .my-slider').forEach(e => {
    tns({
      container: e,
      nav: false,
      loop: false,
      controlsText: [left, right],
      responsive: {
        0: {
          mouseDrag: true,
          items: 1
        },
        768: {
          mouseDrag: true,
          items: e.dataset.items
        }

      }
    })
    e.style.display = "block";
  })

  function updateAnalytics() {
    const MEASURMENT_ID = "G-10690GH9XQ"
    if (window.CookieConsent.acceptedCategories?.includes("analytics")) {
      window['ga-disable-' + MEASURMENT_ID] = undefined
      ;(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.googletagmanager.com/gtag/js?id='+MEASURMENT_ID,'ga')
     
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', MEASURMENT_ID);
    } else {
      window['ga-disable-' + MEASURMENT_ID] = true
    }
  }

  if (!window.testEnvironment){


    if (!window.cc){
      const CookieConsent = window.CookieConsent
      window.cc = new CookieConsent({
        title: "Wir verwenden Cookies 🍪",
        description: `Wir verwenden Cookies. Viele sind notwendig, um die Website und ihre Funktionen zu betreiben, andere sind für statistische oder Marketingzwecke. Mit der Entscheidung "Ablehnen" werden wir deine Privatsphäre respektieren und keine Cookies setzen, die nicht für den Betrieb der Seite notwendig sind.`,
        buttons: {
          acceptAll: "Alle akzeptieren",
          acceptSelected: "akzeptieren",
          reject: "Ablehnen",
          showSettings: "Cookie Einstellungen",
          hideSettings: "Schließen",
        },
        categories: {
          essentials: {
            label: "Essentiell",
            description: `Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.`,
            checked: true,
            mandatory: true
          },
          analytics: {
            label: "Statistik",
            description: `Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden.`
          }
        },
      })
    }

    window.cc.on("change", (cc) => {
      updateAnalytics()
    })

    ahoy.configure({
      visitsUrl: "/poll/visits",
      eventsUrl: "/poll/events",
      cookies: window.cc.acceptedCategories.indexOf('analytics') != -1,
      visitParams: {},
    });

    ahoy.trackView();
    ahoy.trackClicks("a, button, input[type=submit]");
    ahoy.trackSubmits("form");
    updateAnalytics()
  }

  let quantitySelects = document.querySelectorAll('.quantity-select');


  var myModal = document.getElementById('locationModal')
  var myInput = document.getElementById('location-input')
  const inputInput = document.querySelector(`#location-input`)

  function showPosition(position) {
    if (position){
      fetch(`/api/zip/${inputInput.value||"location"}?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`)
        .then((response) => response.json())
        .then((data) => {
          ahoy.track("Zip Input", {zip: data.zip, geolocation: true});

          window.open(data.path, "_self")
          return true
          
          inputInput.value = data.zip
          document.querySelector('#geolocation .loader').style.display = "none"
          document.querySelector('#geolocation svg').style.display = "inline-block"

          if (data.name){
            document.getElementById("strong-location").innerHTML = `Weiter nach ${data.name}`
            document.getElementById("strong-location").parentNode.href = data.path
          }else{
            document.getElementById("strong-location").parentNode.href = "/shop"
            document.getElementById("strong-location").innerHTML = `Weiter zum generellen Shop`
          }
          document.getElementById("location-link").style.display = "block"
        });
    } else {
      alert("Diese Funktion steht dir nicht zur Verfügung.")
      document.querySelector('#geolocation .loader').style.display = "none"
      document.querySelector('#geolocation svg').style.display = "inline-block"
    }
  }



  if (myModal){
    myModal.addEventListener('shown.bs.modal', function () {
      myInput.focus()
    })
    document.getElementById('geolocation').addEventListener('click', (event) => {
      document.querySelector('#geolocation .loader').style.display = "inline-block"
      document.querySelector('#geolocation svg').style.display = "none"
      
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else { 
        alert("Dein Browser untersützt diese Funktion nicht!")
      }
    })
  }

  document.getElementById('location-form').addEventListener('submit', e => {
    e.preventDefault();
    if (document.getElementById("location-link").style.display == "block"){
      window.open(document.getElementById("strong-location").parentNode.href, "_self")
    }
  });

  inputInput.addEventListener('input', (e) => {
    if (inputInput.value.length != 5 && inputInput.value.length != 4){
      document.getElementById("location-link").style.display = "none"
      return false
    }
    ahoy.track("Zip Input", {zip: inputInput.value});
    fetch(`/api/zip/${inputInput.value}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.name){
          document.getElementById("strong-location").innerHTML = `Weiter nach ${data.name}`
          document.getElementById("strong-location").parentNode.href = data.path
        }else{
          document.getElementById("strong-location").parentNode.href = "/shop"
          document.getElementById("strong-location").innerHTML = `Weiter zum generellen Shop`
        }
        document.getElementById("location-link").style.display = "block"
      });
  });

  quantitySelects.forEach(e => {
    e.addEventListener('change', (event) => {
      e.parentElement.parentElement.submit()
    });
  })

  let shippingOptionSelects = document.querySelectorAll('.shipping-option');

  shippingOptionSelects.forEach(e => {
    e.addEventListener('change', (event) => {
      fetch(`/api/delivery_at/${e.dataset.id}/${e.value}`)
        .then((response) => response.json())
        .then((data) => {
          e.parentNode.parentNode.parentNode.querySelector('.delivery_at').innerHTML = data.html
        });
    });
  })

  let stornoBoxs = document.querySelectorAll('.stornobox');

  stornoBoxs.forEach(e => {
    e.addEventListener('change', (event) => {
      const n = document.querySelectorAll('input.stornobox[type="checkbox"]:checked').length

      if (e.checked){
        document.getElementById("storno-quantity-" + e.value).style.display = "block"
      }else{
        document.getElementById("storno-quantity-" + e.value).style.display = "none"
      }
      
      if (n === 0){
        document.getElementById("storno-button").style.display = "none"
      }else{
        document.getElementById("storno-button").querySelector("button").innerHTML = `${n} Artikel stornieren`
        document.getElementById("storno-button").style.display = "block"
      }
    });
  })
  const xs = ["nav-toggel", "menu-toggel"]
  xs.forEach(x => {
    const navToggel = document.querySelector(`#${x}`)
    if (navToggel){
      navToggel.addEventListener('click', (event) => {
        document.querySelectorAll(`.${x}-area`).forEach(e => {
          e.classList.toggle("d-none");
        })
      })
    }
  })

  let searchs = document.querySelectorAll('.main-menu-search');
  searchs.forEach(x => {
    x.addEventListener('submit', (event) => {
      ahoy.track("Search", {input: x.querySelector(".search-input input").value});
    })
  })


  let categoryElements = document.querySelectorAll('.category-element');
  categoryElements.forEach(x => {
    ahoy.track("Category", {main_category_id: x.dataset.mainCategoryId, category_id: x.dataset.categoryId});
  })


  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
}